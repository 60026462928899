import React, { useState, useEffect } from 'react';
import { Middleware } from 'one-ring';
import { connect } from 'react-redux';
import APIEndPoints from '../../services/api';
import { withOktaAuth } from '@okta/okta-react';
import withStyles from '@material-ui/styles/withStyles';
import { DrcPageDataMaintenance, DrcPanel, DrcTooltip, DrcIcons } from 'driscolls-react-components';
import { setNotificationData } from '../../actions/NotificationActions';
import CheckIcon from '@material-ui/icons/Check';

import {
    NOTIFICATION_CREATE,
    NOTIFICATION_UPDATE,
    NOTIFICATION_DELETE,
    LOCATION,
    LOCATION_PLACEHOLDER,
    BERRY_TYPE,
    BERRY_TYPE_PLACEHOLDER,
    MODIFIED_BY,
    MODIFIED_DATE_TIME
} from '../../language/english';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { getNotificationPayload, getNotificationDeletePayload } from '../../util/utils';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import DrcMultiSelect from '../../components/DrcMultiSelect';
import { DuDateUtilities } from 'driscolls-react-utilities';

const PAGE_TYPE = 'notification';

let styles = (theme) => ({
    main: {
        margin: 0,
        paddingBottom: '0px !important'
    },
    grid: {
        '& .selected > div': {
            backgroundColor: '#cfc3deba !important'
        },
        '& .nonSelected > div': {
            backgroundColor: '#d9d0e4 !important'
        },
        '& .react-grid-Toolbar .btn:after': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important',
            height: '27px',
            color: 'white'
        },
        '& .react-grid-Header': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important'
        }
    },
    success: {
        color: theme.palette.primary.main
    },
    gridContainer: {
        paddingLeft: '24px'
    },
    layout: {
        width: '97%'
    },
    dialog: {
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '600px',
            width: '100%'
        },
        '& .p-datatable-thead': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important',
            color: '#fff'
        },
        '& .p-datatable-row': {
            fontWeight: 600
        },
        '& .p-datatable-scrollable-wrapper': {
            maxHeight: 600
        }
    }
});

const allBerries = (berryList) => (
    <>
        <DrcTooltip tipText="BLACK">
            <span style={!(berryList.includes('BLACK') || berryList.includes(1)) ? { opacity: '0.1' } : null}>
                {DrcIcons.GetSmallBerryIcon('BLACK')}
            </span>
        </DrcTooltip>
        <DrcTooltip tipText="BLUE">
            <span style={!(berryList.includes('BLUE') || berryList.includes(2)) ? { opacity: '0.1' } : null}>
                {DrcIcons.GetSmallBerryIcon('BLUE')}
            </span>
        </DrcTooltip>
        <DrcTooltip tipText="RASP">
            <span style={!(berryList.includes('RASP') || berryList.includes(3)) ? { opacity: '0.1' } : null}>
                {DrcIcons.GetSmallBerryIcon('RASP')}
            </span>
        </DrcTooltip>
        <DrcTooltip tipText="STRAW">
            <span style={!(berryList.includes('STRAW') || berryList.includes(4)) ? { opacity: '0.1' } : null}>
                {DrcIcons.GetSmallBerryIcon('STRAW')}
            </span>
        </DrcTooltip>
    </>
);

const BerryFormatter = (value) => {
    return value ? allBerries(value) : null;
};

const CheckBoxFormatter = (value) => {
    return value ? <CheckIcon fontSize="inhertit" style={{ color: '#563e70', pointerEvents: 'none' }} /> : null;
};

const getColumn = (value, row) => {
    for (let key in row) {
        if (row[key] === value) {
            return key;
        }
    }
};

const cellFormatter = (value, row) => {
    let column = getColumn(value, row);
    if (column === 'NotifyName') {
        return (
            <span style={{ fontWeight: '500', fontSize: '13px' }} title={value}>
                {value}
            </span>
        );
    } else if (column === 'NotifyEmailAddress' || column === 'ModifiedBy' || column === 'CreatedBy') {
        return (
            <span>
                <a style={{ fontWeight: '500', fontSize: '13px', color: '#6f5091', textDecoration: 'none' }} href={'mailto:' + value} title={value}>
                    {value}
                </a>
            </span>
        );
    } else if (column === 'ModifiedDateTime' || column === 'CreatedDateTime') {
        return (
            <span style={{ fontWeight: '500', fontSize: '13px' }} title={DuDateUtilities.FormatDateTimeFromIso(value)}>
                {DuDateUtilities.FormatDateTimeFromIso(value)}
            </span>
        );
    }
    return (
        <span style={{ fontWeight: '500', fontSize: '13px' }} title={value}>
            {value}
        </span>
    );
};
const berryOptions = (arr) =>
    arr.map((berry) => ({
        label: (
            <>
                {DrcIcons.GetSmallBerryIcon(berry.value)}
                <span style={{ marginLeft: '10px', lineHeight: '22px', verticalAlign: 'top' }}>{berry.label}</span>
            </>
        ),
        value: berry.label
    }));

const Notification = (props) => {
    const [location, setLocation] = useState('');

    const [columns, setColumns] = useState([
        {
            key: 'NotifyName',
            name: (
                <DrcTooltip tipText="Name">
                    <span>Name</span>
                </DrcTooltip>
            ),
            filter: true,  
            frozen: true,          
            width: 90,
            validationType: 'required',
            isRequired: true,            
            columnTemplate: (row) => cellFormatter(row.NotifyName, row)

        },
        {
            key: 'NotifyEmailAddress',
            name: (
                <DrcTooltip tipText="Email">
                    <span>Email</span>
                </DrcTooltip>
            ),
            filter: true,
            width: 200,
            validationType: 'email',
            editDisabled: true,
            addDisabled: false,
            isRequired: true,
            columnTemplate: (row) => cellFormatter(row.NotifyEmailAddress, row)
        },
        {
            key: 'BerryType',
            name: (
                <DrcTooltip tipText="Berry Type">
                    <span>Berry Type</span>
                </DrcTooltip>
            ),
            filter: true,
            inputType: 'Checkbox',
            isRequired: true,
            width: 95,
            color: '#563e70',            
            columnTemplate: (row) => BerryFormatter(row.BerryType),
            options: ['BLACK', 'BLUE', 'RASP', 'STRAW']
        },
        {
            key: 'NotifyLocation',
            name: (
                <DrcTooltip tipText="Location">
                    <span>Location</span>
                </DrcTooltip>
            ),
            filter: true,
            isDisabled: true,
            displayValue: !location ? props.quartileLocation[0] && props.quartileLocation[0].label : location.label,
            columnTemplate: (row) => cellFormatter(row.NotifyLocation, row)
        },

        {
            key: 'IsNotifyOnReject',
            name: (
                <DrcTooltip tipText="Notify on Reject">
                    <span>Notify on Reject</span>
                </DrcTooltip>
            ),
            filter: true,
            columnTemplate: (row) => CheckBoxFormatter(row.IsNotifyOnReject),
            inputType: 'Checkbox',
            color: '#563e70',
            isRequired: false
        },
        {
            key: 'IsNotifyOnAlmostReject',
            name: (
                <DrcTooltip tipText="Notify on Almost Reject">
                    <span>Notify on Almost Reject</span>
                </DrcTooltip>
            ),
            filter: true,
            columnTemplate: (row) => CheckBoxFormatter(row.IsNotifyOnAlmostReject),
            inputType: 'Checkbox',
            color: '#563e70',
            isRequired: false
        },
        {
            key: 'IsNotifyOnAll',
            name: (
                <DrcTooltip tipText="Notify on All">
                    <span>Notify on All</span>
                </DrcTooltip>
            ),
            filter: true,
            columnTemplate: (row) => CheckBoxFormatter(row.IsNotifyOnAll),
            inputType: 'Checkbox',
            color: '#563e70',
            isRequired: false
        },
        {
            key: 'AlmostRejectThreshold',
            name: (
                <DrcTooltip tipText="Almost Reject Threshold">
                    <span>Almost Reject Threshold</span>
                </DrcTooltip>
            ),
            filter: true,
            validationType: 'number',
            isRequired: false,
            columnTemplate: (row) => cellFormatter(row.AlmostRejectThreshold, row)
        },
        {
            key: 'WeeklyReport',
            name: (
                <DrcTooltip tipText="Weekly Report">
                    <span>Weekly Report</span>
                </DrcTooltip>
            ),
            filter: true,
            columnTemplate: (row) => CheckBoxFormatter(row.WeeklyReport),
            inputType: 'Checkbox',
            color: '#563e70',
            isRequired: false
        },
        {
            key: 'DailyReport',
            name: (
                <DrcTooltip tipText="Daily Report">
                    <span>Daily Report</span>
                </DrcTooltip>
            ),
            filter: true,
            columnTemplate: (row) => CheckBoxFormatter(row.DailyReport),
            inputType: 'Checkbox',
            color: '#563e70',
            isRequired: false
        },
        {
            key: 'language',
            name: (
                <DrcTooltip tipText="Language">
                    <span>Language</span>
                </DrcTooltip>
            ),
            filter: true,
            inputType: 'Radio',
            color: '#563e70',
            options: ['English', 'Spanish'],
            columnTemplate: (row) => cellFormatter(row.language, row)
        },
        {
            key: 'ModifiedBy',
            name: (
                <DrcTooltip tipText={MODIFIED_BY}>
                    <span>{MODIFIED_BY}</span>
                </DrcTooltip>
            ),
            filter: true,
            width: 180,
            filterable: true,
            resizable: true,
            columnTemplate: (row) => cellFormatter(row.ModifiedBy, row),
            isDisabled: true,
            isHidden: true
        },
        {
            key: 'ModifiedDateTime',
            name: (
                <DrcTooltip tipText={MODIFIED_DATE_TIME}>
                    <span>{MODIFIED_DATE_TIME}</span>
                </DrcTooltip>
            ),
            filter: true,
            width: 180,
            filterable: true,
            resizable: true,
            columnTemplate: (row) => cellFormatter(row.ModifiedDateTime, row),
            isDisabled: true,
            isHidden: true
        }
    ]);

    const [multiBerryType, setMultiBerryType] = useState([]);
    const [currentToken, setCurrentToken] = useState('');
    const [previousLocation, setPreviousLocation] = useState('');
    const [messageDialog, setMessageDialog] = useState({
        showMessage: false,
        message: '',
        type: '',
        severity: 'success'
    });

    useEffect(() => {
        setLocation(props.quartileLocation[0]);
        getData(props.quartileLocation[0], '', '', true);
    }, []);

    const getData = (location, selectedBerry, message, defaultLoadingMessage) => {
        if (location) {
            let url = '';
            let Berries = '';
            if (selectedBerry && selectedBerry.length) {
                for (let i = 0; i < selectedBerry.length; i++) {
                    Berries = Berries + '&berrytype=' + selectedBerry[i].value;
                }
            }
            url = location.label + Berries;

            props.authService.getAccessToken().then((token) => {
                Middleware.Send(
                    PAGE_TYPE,
                    token,
                    APIEndPoints.NOTIFICATION_DATA(url),
                    'GET',
                    { Type: PAGE_TYPE },
                    { loadingMessage: defaultLoadingMessage ? 'Loading' : 'Fetching notification...' }
                )
                    .then((res) => {
                        props.setNotificationData(res);
                        setCurrentToken(token);
                        if (message) {
                            setMessageDialog({
                                showMessage: true,
                                message: message,
                                type: '',
                                severity: 'success'
                            });
                        }
                    })
                    .catch((error) => {
                        let errMessage = error.data.response.data.message || 'Something happened. but I am not sure if it is good or bad!';
                        setMessageDialog({
                            showMessage: true,
                            message: errMessage,
                            type: '',
                            severity: 'error'
                        });
                    });
            });
        }
    };

    const handleLocationChange = (option) => {
        if (previousLocation.value !== option.value) {
            setPreviousLocation(option);
            if (option.label !== location) {
                setLocation(option);
                getData(option, multiBerryType);
                let updatecolumns = [...columns];
                updatecolumns[3].displayValue = option.label;
                setColumns(updatecolumns);
            }
        }
    };

    const handleBerryTypeChange = (berryType) => {
        setMultiBerryType(berryType);
        getData(location, berryType);
    };

    const onEdit = (oldValue, newValue) => {
        let payload = getNotificationPayload(newValue, currentToken, oldValue);
        props.authService.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.POST_NOTIFICATION_DATA, 'POST', payload, {
                overrideRequestMapping: true,
                loadingMessage: 'Updating notification...'
            })
                .then((_res) => {
                    getData(location, multiBerryType, NOTIFICATION_UPDATE);
                })
                .catch((error) => {
                    let message = error.data.response.data.message || 'Something happened. but I am not sure if it is good or bad!';
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        type: '',
                        severity: 'error'
                    });
                });
        });
    };

    const onDelete = (deleteVal) => {
        let payload = getNotificationDeletePayload(deleteVal, currentToken);
        props.authService.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.POST_NOTIFICATION_DATA, 'POST', payload, {
                overrideRequestMapping: true,
                loadingMessage: 'Deleting notification...'
            })
                .then((_res) => {
                    let updatedData = props.notificationData.filter((item) => item.NotifyId !== deleteVal.NotifyId);
                    props.setNotificationData(updatedData);
                    setMessageDialog({
                        showMessage: true,
                        message: NOTIFICATION_DELETE,
                        type: '',
                        severity: 'success'
                    });
                })
                .catch((error) => {
                    let message = error.data.response.data.message || 'Something happened. but I am not sure if it is good or bad!';
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        type: '',
                        severity: 'error'
                    });
                });
        });
    };

    const onAdd = (addObj) => {
        let payload = getNotificationPayload(addObj, currentToken, '', location);
        props.authService.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.POST_NOTIFICATION_DATA, 'POST', payload, {
                overrideRequestMapping: true,
                loadingMessage: 'Creating notification...'
            })
                .then((_res) => {
                    getData(location, multiBerryType, NOTIFICATION_CREATE);
                })
                .catch((error) => {
                    let message = error.data.response.data.message || 'Something happened. but I am not sure if it is good or bad!';
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        type: '',
                        severity: 'error'
                    });
                });
        });
    };

    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessageDialog({ showMessage: false });
    };

    const gridDataFormatter = (data) => {
        const modifiedData = [...data];
        if (modifiedData.length) {
            if (!modifiedData[0].language) {
                modifiedData[0].language = 'English';
            }
            modifiedData[0].ModifiedDateTime = new Date().toISOString();
            modifiedData[0].ModifiedBy = props.loggedInUser;
            return modifiedData;
        } else {
            return [];
        }
    };
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    if (!MasterDataUtilities.Check(props.isMasterDataInitialized)) {
        return MasterDataUtilities.Redirect();
    }

    let settings = {
        EnableDelete: true,
        ActionColumnSetting: 2,
        EnableExport: false,
        ShowCount: false
    };
    let textOptions = {
        AddBtn: 'Create new'
    };
    const { classes } = props;

    return (
        <DrcPanel style={{ maxWidth: '100%', border: 'none', boxShadow: '0 2px 4px 0 #C3C3C3', margin: '0 auto 0' }} className={props.classes.layout}>
            <Grid container className={classes.gridContainer}>
                <Grid container spacing={1} alignItems="flex-start">
                    <Grid item xs={6} sm={3} md={3} lg={2}>
                        <DrcMultiSelect
                            label={LOCATION}
                            placeholder={LOCATION_PLACEHOLDER}
                            value={location}
                            options={props.quartileLocation}
                            onChange={handleLocationChange}
                            name="location"
                        ></DrcMultiSelect>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <DrcMultiSelect
                            options={berryOptions(props.berryType)}
                            label={BERRY_TYPE}
                            placeholder={BERRY_TYPE_PLACEHOLDER}
                            isMulti={true}
                            onChange={handleBerryTypeChange}
                            name="berryType"
                        ></DrcMultiSelect>
                        {/* <BerryTypeSelect
                            options={props.berryType}
                            isMulti={true}
                            onChange={handleBerryTypeChange}
                        ></BerryTypeSelect> */}
                    </Grid>
                </Grid>
            </Grid>
            <DrcPageDataMaintenance
                className={classes.dialog}
                columns={columns}
                classes={classes}
                data={props.notificationData}
                fullWidth={true}
                onAdd={onAdd}
                onDelete={onDelete}
                settings={settings}
                textOptions={textOptions}
                type="Notification"
                gridDataFormatter={gridDataFormatter}
                resultCount={props.notificationData.length}
                addBtn={true}
                fullHeightOffset={10}
                readOnly={false}
                hideCount={false}
                addText="Create New"
                onEdit={onEdit}
            />
            <Snackbar open={messageDialog.showMessage} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={messageDialog.severity}>
                    {messageDialog.message}
                </Alert>
            </Snackbar>
        </DrcPanel>
    );
};

const mapStateToProps = (state) => {
    return {
        notificationData: state.notificationReducer.notificationData,
        quartileLocation: state.masterReducer.quartileLocation,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        berryType: state.pointMatrixReducer.berryType,
        loggedInUser: state.masterReducer.loggedInUser
    };
};
export default connect(mapStateToProps, { setNotificationData })(withOktaAuth(withStyles(styles)(Notification)));
